@keyframes heartbeat-gets-bigger {
	0% {
		transform: scale(1);
	}
	20% {
		transform: scale(1.25);
	}
	40% {
		transform: scale(1);
	}
	60% {
		transform: scale(1.25);
	}
	80% {
		transform: scale(1);
	}
	100% {
		transform: scale(1);
	}
}

@keyframes heartbeat-gets-smaller {
	0% {
		transform: scale(1);
	}
	20% {
		transform: scale(0.75);
	}
	40% {
		transform: scale(1);
	}
	60% {
		transform: scale(0.75);
	}
	80% {
		transform: scale(1);
	}
	100% {
		transform: scale(1);
	}
}
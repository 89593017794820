/**
 * Import base Bootstrap (Variables & Mixins)
 * Current Version: 5.3.2
 */
@import "@bootstrap/scss/functions";

// Import Bootstrap Overrides *****************************************************************************
@import "@styles/variables/bootstrap-variables";
@import "@styles/utilities/bootstrap-utilities";
@import '@styles/variables/alerts-variables';
@import '@styles/variables/list-group-variables';

@import "@bootstrap/scss/variables";
@import "@bootstrap/scss/mixins";

/**
 * 1. Base Styles
 * Similar to "subatomic particles" in atomic design. Elements that will be used to create an atom.
 * Such as: LESS variables, LESS mixins, etc... Defining things like our colors, importing our fonts, etc.
 */
// Import Bootstrap LESS styles from npm package
// Note: Only relevant Bootstrap styles will be loaded in here!
@import '@styles/variables/global-variables'; // <-- Import before Bootstrap, so we can reference any custom variables in the Bootstrap overrides.
@import '@styles/bootstrap';

// Voices.com Core Styles
@import '@styles/mixins/global-mixins';
@import './fonts';
@import './animations';

// FontAwesome Pro 5.10.2 (licenced)
@import '@styles/fonts/fontawesome-pro-5.10.2.scss';
@mixin transition($transition){
	-webkit-transition: $transition;
	-moz-transition: $transition;
	-ms-transition: $transition;
	-o-transition: $transition;
	transition: $transition;
}

@mixin clearfix {
	&::after {
		display: block;
		clear: both;
		content: "";
	}
}

.clearfix {
	@include clearfix;
}

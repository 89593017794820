/*!
* Font Awesome Pro 5.10.2 by $fontawesome - https://fontawesome.com
* License - https://fontawesome.com/license (Commercial License)
*/
@import "./fontawesome-pro-5.10.2/_variables";

@import "./fontawesome-pro-5.10.2/_mixins";
@import "./fontawesome-pro-5.10.2/_core";
@import "./fontawesome-pro-5.10.2/_larger";
@import "./fontawesome-pro-5.10.2/_fixed-width";
@import "./fontawesome-pro-5.10.2/_animated";
@import "./fontawesome-pro-5.10.2/_screen-reader";

@import "./fontawesome-pro-5.10.2/brands";
@import "./fontawesome-pro-5.10.2/duotone";
@import "./fontawesome-pro-5.10.2/light";
@import "./fontawesome-pro-5.10.2/regular";
@import "./fontawesome-pro-5.10.2/solid";

@import "./fontawesome-pro-5.10.2/list";
@import "./fontawesome-pro-5.10.2/bordered-pulled";
@import "./fontawesome-pro-5.10.2/rotated-flipped";
@import "./fontawesome-pro-5.10.2/stacked";
@import "./fontawesome-pro-5.10.2/icons";
@import "./fontawesome-pro-5.10.2/v4-shims";
@import '@styles/variables/global-variables';

#frontdoor-modal {
	text-align: center; // center align modal

	.modal-dialog {
		z-index: 100001;
		text-align: left;

		@media (min-width: 768px) {
			width: 650px;
		}

		.modal-content {
			@media (min-width: 768px) {
				margin: 0;
			}
		}
	}

	.modal-header {
		padding: 0;
		min-height: auto;
	}

	.modal-body {
		padding: 2.4rem;
		@media (min-width: 768px) {
			padding: 5.6rem;
		}
	}

	button[data-bs-dismiss="modal"] .fa-times {
		color: $grey1;
	}

	#login-form h2 {
		text-align: center;
	}
}

#login-form {
	h2 {
		font-family: poppins, sans-serif;
		font-weight: 700;
		color: $text-dark;
		margin-top: 0;
		margin-bottom: $spacing-large;
	}

	.form-cta-text {
		font-size: 16px;
	}

	.form-group {
		margin-bottom: 24px;
	}

	.password-form-group {
		margin-bottom: 16px;
	}

	.login-sso-divider-line {
		position: relative;
		width: 100%;
		height: 2.4rem;
		margin-top: 2.4rem;
		font-family: Roboto;
		font-size: 1.6rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.5;
		letter-spacing: normal;
		text-align: center;
		color: $grey2;

		&:before {
			content: ' ';
			position: absolute;
			width: 100%;
			height: 0.1rem;
			top: 50%;
			left: 0;
			background-color: $grey4;
		}

		span {
			border: 15px solid white;
			background: $white;
			position: relative;
		}
	}

	.login-sso-btn {
		display: flex;
		align-items: center;
		justify-content: center; // centered text
		background-color: $white;
		background-size: 18px;
		background-repeat: no-repeat;
		background-position: 10px 50%;
		color: $text-dark;
		font-size: 14px;
		font-weight: 500; // medium
		border-radius: 4px;
		border: 1px solid $grey4;
		box-shadow: none;
		white-space: nowrap;
		width: 100%;
		height: 48px;
		margin: 1.3rem auto;
		transition: all .2s linear;
		@media (min-width: 768px) {
			font-size: 18px;
		}

		&:hover {
			cursor: pointer;
			background-color: $grey4;
		}

		&:focus {
			border: solid 2px $ice2;
		}

		&[disabled] {
			pointer-events: none;
			opacity: 0.4;
		}
	}
}


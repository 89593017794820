// Password Input Group - View/Hide Icon
.password-input-group,
.email-input-group {
	position: relative;

	&:has(.delete-btn){
		input {
			padding-right: $spacing-largest;
		}
	}

	#password,
	#confirm {
		padding-right: 6rem;
	}

	.form-password-show,
	.delete-btn {
		cursor: pointer;
		display: flex;
		align-items: center;
		width: auto;
		border: none;
		height: 100%;
		position: absolute;
		top: 0;
		right: 0;

		.fa,
		.far {
			color: $grey1;
			padding: 0;
			margin-right: 1.8rem;
			margin-left: 1.8rem;
		}

		&:focus {
			outline: none;
		}
	}

	.valid-password {
		display: flex;
		align-items: center;
		width: auto;
		border: none;
		height: 100%;
		position: absolute;
		@media screen and (max-width: 479px) {
			margin-left: 0.5rem;
		}

		.far {
			color: #1171bb;
			font-size: 2rem;
			margin-left: 0.4em;
		}
	}

	.delete-btn {
		transition: color 0.3s ease-in-out;

		&:hover,
		&:focus {
			.far {
				color: $danger;
			}
		}

		.far {
			margin-right: $spacing-medium;
			margin-left: $spacing-medium;
		}
	}
}
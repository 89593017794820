// TODO: convert all sizing related styles to rems if we no longer need to support alert styles for global & globalClassic

$alert-padding : 16px; // TODO: convert to 1.6rem?
$alert-border-radius : 4px;

$alert-default-bg : $grey5;
$alert-default-text : $text-dark;
$alert-default-border : $grey5;
$alert-default-accent-color : $grey1;

$alert-success-bg : $green5;
$alert-success-text : $text-dark;
$alert-success-border : $green5;
$alert-success-accent-color : $green1;

$alert-info-bg : $blue5;
$alert-info-text : $text-dark;
$alert-info-border : $blue5;
$alert-info-accent-color : $blue1;

$alert-warning-bg : $yellow5;
$alert-warning-text : $text-dark;
$alert-warning-border : $yellow5;
$alert-warning-accent-color : $yellow1;

$alert-danger-bg : $red5;
$alert-danger-text : $text-dark;
$alert-danger-border : $red5;
$alert-danger-accent-color : $red1;


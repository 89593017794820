@import '@styles/variables/global-variables';

#signup-form {
	h2 {
		margin-top: 1.6rem;
		margin-bottom: 0.5rem;
		font-family: poppins, sans-serif;
		font-weight: 700;
		color: $text-dark;
		text-align: center;
	}
	p{
		color: $grey1;
		margin-bottom: $spacing-large;
		font-size: 1.6rem;
	}
	input::placeholder{
		color: $grey2;
	}
	input{
		color:$grey1;
	}

}

#signup-form-container {
	margin-top: $spacing-larger !important;
	background: $white;
	border-radius: 8px;
	box-shadow: 0 2px 5px 0 rgba(25, 34, 43, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
	padding: $spacing-large;
	max-width: 95%;
	@media (min-width: 768px) {
		padding: $spacing-huge;
		margin-top: $spacing-gigantic;
		width: 57.8rem;
		margin-left: auto;
		margin-right: auto;
	}

	#submit-signup {
		display: block;
	}
}

#voices-signup {
	#signup-back-link {
		color: $grey1;
		font-size: 1.4rem;

		i {
			margin-right: $spacing-smallest;
		}
	}

	#policy-checkbox-container {
		& > label {
			margin-bottom: 0;
		}

		.checkbox label {
			&::before,
			&::after {
				top: -8px;
			}
		}
	}
	label.text-sm.marginTop{
		margin-top: 5px;
		font-weight: 400;
	}
}

.btn.form-btn {
	display: block;
	width: 100%;
	padding: 12px;
	font-size: 1.8rem;
	transition: all 0.3s ease-in-out;

	&-submit {
		margin: 0 !important;
	}
}

.sign-up-option-divider {
	width: 100%;
	overflow: hidden;
	font-size: 1.6rem;
	font-weight: 400;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	color: $grey2;
	text-align: center;
	display: block;
	margin: $spacing-medium auto;

	&::before,
	&::after {
		background-color: $grey5;
		content: '';
		display: inline-block;
		height: 1px;
		position: relative;
		vertical-align: middle;
		width: 50%;
	}

	&::before {
		right: 0.5em;
		margin-left: -50%;
	}

	&::after {
		left: 0.5em;
		margin-right: -50%;
	}
}

.google-container {
	display: flex;
	justify-content: center;
}

.apple-container {
	display: flex;
	justify-content: center;

	.icon {
		background: url('#{$assetPath}/images/account/apple.png') transparent 0 0 no-repeat;
	}
}

.customSocialSignIn {
	display: flex;
	align-items: center;
	border-radius: 4px;
	border: solid 1px $grey4;
	white-space: nowrap;
	width: 250px;
	height: 4rem;
	padding: 1.2rem;

	&:hover {
		cursor: pointer;
		background-color: $grey4;
	}

	.icon {
		width: 2.5rem;
		height: 1.8rem;
		background-size: 1.8rem;
		background-position: 50%;
		display: inline-block;
		vertical-align: middle;
	}

	.text {
		font-size: 1.4rem;
		font-weight: 500;
		line-height: 1.6rem;
		color: $text-dark;
		width: 100%;
		display: flex;
		align-items: flex-end;
		justify-content: center;
		font-family: sans-serif;
	}
}


.social-info-container {
	height: 10.8rem;
	margin: 0 auto 2.4rem;
	padding: 2.4rem;
	border-radius: 8px;
	border: solid 1px $grey4;
	background-color: rgba(255, 255, 255, 0);
	display: flex;
	justify-content: flex-start;
	align-items: center;
	overflow-wrap: break-word;

	&.social-full-name-capture {
		height: 20.8rem;

		> div {
			height: 13.8rem;
			text-align: center;
			width: 100%;
		}

		.form-group {
			text-align: left;
			margin-top: 2.4rem;
		}

		.social-info-email {
			max-width: 30rem;
			overflow: hidden;
			height: 2.4rem;
			font-size: 1.8rem;
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.5;
			letter-spacing: normal;
			color: $text-dark;
		}
	}

	.social-info-picture-container {
		width: 6rem;
		margin: 0 1.3rem 0 0;
		height: 6rem;
		border-radius: 50px;
		overflow: hidden;
	}

	.social-info-details-container {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
	}

	.social-info-name {
		max-width: 30rem;
		overflow: hidden;
		height: 2.4rem;
		font-size: 1.6rem;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.5;
		letter-spacing: normal;
		color: $text-dark;
	}

	.social-info-email {
		max-width: 30rem;
		overflow: hidden;
		height: 2.1rem;
		font-size: 1.4rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.5;
		letter-spacing: normal;
		color: $grey1;
	}
}

.form-input-message-error {
	line-height: 1.4;
}
$poppins-path: if(development = development, '/assets/fonts/poppins',
           if(development = systems, 'https://cdn.voices.systems/assets/fonts/poppins',
           'https://static.voices.com/assets/fonts/poppins')); 

@font-face {
    font-family: 'poppins';
    src: url('#{$poppins-path}/Poppins-Bold.woff2') format('woff2'); 
    font-display: swap;
}

.poppins-h1, .poppins-h2 {
    font-family: poppins, sans-serif;
    margin: 1.6rem 0;
    font-weight: bold;
}
.poppins-h1 {
	font-size: 2.8rem;

    @media screen and (min-width: $screen-sm) {
		font-size: 3.6rem;
	}
}
.poppins-h2 {
	font-size: 2.2rem;

	@media screen and (min-width: $screen-sm) {
		font-size: 2.8rem;
	}
}

.xl-heading {
    font-family: poppins, sans-serif;
	font-size: 3.6rem;
    margin: 2.4rem 0;

	@media screen and (min-width: $screen-sm) {
		font-size: 4.8rem;
    }
}

.h2-mini { 
    font-family: poppins, sans-serif;
	font-size: 2.0rem;
    margin: 1.6rem 0;

	@media screen and (min-width: $screen-sm) {
        font-size: 2.4rem;
    }
}

@import '@styles/variables/global-variables';

@import "@bootstrap/scss/alert";

@import '@styles/fonts/fontawesome-pro-5.10.2/variables';


// ===============================================================
// Voices.com Alerts
// ===============================================================

// TODO: convert all sizing related styles to rems if we no longer need to support alert styles for global & globalClassic
$alert-icon-font-size: 20px;

// Define FontAwesome Icons (Only the things we need!)
.#{$fa-css-prefix}-bookmark:before { content: $fa-var-bookmark; }
.#{$fa-css-prefix}-check-circle:before { content: $fa-var-check-circle; }
.#{$fa-css-prefix}-exclamation-circle:before { content: $fa-var-exclamation-circle; }
.#{$fa-css-prefix}-exclamation-triangle:before { content: $fa-var-exclamation-triangle; }
.#{$fa-css-prefix}-info-circle:before { content: $fa-var-info-circle; }

.alert-dismissable,
.alert-dismissible {
	padding-right: 35px;
	.close {
		position: relative;
		top: -2px;
		right: -21px;
	}
}

.alert {
	position: relative;
	display: flex;
	margin-bottom: $alert-padding;
	border: 1px solid transparent;
	border-left-width: 6px;
	color: $alert-default-text;
	padding: $spacing-medium;

	// ------ Close Button ----------------------
	.close {
		position: absolute;
		top: auto !important; // make !important, in case alert is placed within modal, otherwise .modal .close will override this...
		right: $alert-padding !important; // make !important, in case alert is placed within modal, otherwise .modal .close will override this...
		opacity: 1;
		padding: 0;
		cursor: pointer;
		background: transparent;
		border: 0;
	}

	// ------ Icon Styles ----------------------
	.alert-icon-block {
		position: absolute;
		line-height: 1;
		&[aria-hidden="true"] {
			display: block; // some legacy styles are forcing this to be display:none; but we always want the icon to show
		}
		i {
			font-size: $alert-icon-font-size;
		}
	}

	// ------ Content Styles ----------------------
	.alert-body-block {
		padding-left: calc($alert-icon-font-size + $alert-padding);
		width: 100%;
		text-align: left;
	}

	.alert-body-title {
		line-height: 1;
		strong {
			display: block;
			font-size: 16px;
			font-weight: 700;
			line-height: 1.25;
		}
	}

	.alert-body-content {
		font-size: 14px;
		font-weight: 400;
		line-height: 1.5;
		word-break: break-word;

		*:last-child {
			margin-bottom: 0;
		}

		ul {
			padding-left: 20px;
		}

		a {
			font-weight: 500;
			&:hover,
			&:active {
				text-decoration-skip-ink: auto;
				border-bottom: 1px solid $blue0;
			}
		}
	}

	p, ul {
		margin-bottom: 0;
		~ p {
			margin-top: 5px;
		}
	}

	// ------ Color Styles ----------------------
	// Default Alerts (grey)
	&-default {
		border-color: $alert-default-accent-color;
		background-color: $alert-default-bg;
		color: $alert-default-text;
		.alert-icon-block i {
			color: $alert-default-accent-color;
		}
		.close {
		  color: $white;
		}
	}

	// Success Alerts (green)
	// Note: backgrounds & text color is defined via Bootstrap.
	// To alter those styles, make adjustments to the `$alert-success-*` Bootstrap variables in the bootstrap-variables.scss file.
	&-success {
		border-color: $alert-success-accent-color;
		background-color: $alert-success-bg;
		.alert-icon-block i {
			color: $alert-success-accent-color;
		}
	}

	// Informational Alerts (blue)
	// Note: backgrounds & text color is defined via Bootstrap.
	// To alter those styles, make adjustments to the `$alert-info-*` Bootstrap variables in the bootstrap-variables.scss file.
	&-info {
		border-color: $alert-info-accent-color;
		background-color: $alert-info-bg;
		.alert-icon-block i {
			color: $alert-info-accent-color;
		}
	}

	// Warning Alerts (yellow)
	// Note: backgrounds & text color is defined via Bootstrap.
	// To alter those styles, make adjustments to the `$alert-warning-*` Bootstrap variables in the bootstrap-variables.scss file.
	&-warning {
		border-color: $alert-warning-accent-color;
		background-color: $alert-warning-bg;
		.alert-icon-block i {
			color: $alert-warning-accent-color;
		}
	}

	// Error / Danger Alerts (red)
	// Note: backgrounds & text color is defined via Bootstrap.
	// To alter those styles, make adjustments to the `$alert-danger-*` Bootstrap variables in the bootstrap-variables.scss file.
	&-danger {
		border-color: $alert-danger-accent-color;
		background-color: $alert-danger-bg;
		.alert-icon-block i {
			color: $alert-danger-accent-color;
		}
	}

	// ------ Dismissible Alert ----------------------
	button[data-bs-dismiss="alert"] {
		line-height: 1;
		font-size: inherit;
		i {
			font-size: $alert-icon-font-size;
		}
	}

	&-dismissible {
		padding-right: 35px;

		&.fade-in {
			transition: 0.3s ease opacity;
			&.out {
				opacity: 0;
			}
		}
		&.slide-in {
			position:fixed;
			z-index: 99999;
			top:0;
			left: 50%;
			transform: translate(-50%, -100%);
			transition: 0.3s ease transform, 0.3s ease opacity;
			opacity:1;
			width: calc(100% - 30px);
			@media (min-width:768px){ // $screen-sm
				width:720px;
			}
			@media (min-width:992px){ //$screen-md
				width:940px;
			}
			@media (min-width:1200px){ //$screen-lg
				width:1140px
			}
			&.slide-in-transitioned {
				transform: translate(-50%, .8rem);
				&.out {
					opacity: 0; // fade-out instead of slide back up, since that looks weird
				}
			}

			// Colour Tweaks...
			&.alert-default {
				box-shadow: -1px 0 1px $alert-default-bg;
			}
			&.alert-success {
				box-shadow: -1px 0 1px $alert-success-bg;
			}
			&.alert-info {
				box-shadow: -1px 0 1px $alert-info-bg;
			}
			&.alert-warning {
				box-shadow: -1px 0 1px $alert-warning-bg;
			}
			&.alert-danger {
				box-shadow: -1px 0 1px $alert-danger-bg;
			}
		}
	}

	// ------ Inline Alert ----------------------
	&-inline {
		border-left-width: 1px;
	}

}

// This for on-page-load service messages
.service-messages {
	display: inline-block;
	.alert {
		margin-top: $alert-padding;
		margin-bottom: 0;
	}
}

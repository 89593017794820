// Icon Sizes
// -------------------------

@mixin larger($factor) {
	@for $i from 1 through $factor {
		.#{$fa-css-prefix}-#{$i}x {
			font-size: calc($i * 1em);
		}
	}
}

/* makes the font 33% larger relative to the icon container */
.#{$fa-css-prefix}-lg {
  font-size: calc(4em / 3);
  line-height: calc(3em / 4);
  vertical-align: -.0667em;
}

.#{$fa-css-prefix}-xs {
  font-size: .75em;
}

.#{$fa-css-prefix}-sm {
  font-size: .875em;
}

@include larger(10);
